@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@100;200;300;400;500;600;700;800;900');

body {
  font-family: 'Poppins';
  margin: 0;
  overflow: auto !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body * {
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: 'none' /* IE and Edge */;
  scrollbar-width: 'none' /* Firefox */;
}

body *::-webkit-scrollbar {
  /* Hide scrollbar for Chrome, Safari and Opera */
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
